.sidebar-wrap {
    height: inherit;
    // width: 20%;
    // flex: 2;
    background: #29273A;
    width: 20%;
    // position: fixed;
    transition: all 0.1s ease;
    // overflow-y: scroll;

}

.sidebar-wrap.close {
    width: 7%;
    transition: all 0.1s ease;

    .menu-wrap {
        .menuitem {
            justify-content: center;
        }

        .names {
            display: none;
        }
    }
}