@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tajawal', sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  
height: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
:where(.css-k83k30).ant-modal .ant-modal-title{
  font-size: 30px !important;
}
.my-table .ant-table-thead {
  background: red;
  display: none !important;
}
.ant-table-thead th{
  font-size: 13px !important;
}
.rc-virtual-list-holder-inner{
  padding-left:10px !important;
  padding-right: 10px !important;
}

.ant-select-item-option-active:hover{
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background-color: rgba(255,255,255,0) !important;
}
/* .bg-bginputfield.text-gray-400{
  color: black !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type=number] {
  -moz-appearance: textfield !important;
}
/* 
.ant-radio-checked .ant-radio-inner{
  border-color: red !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: red !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red !important;
}

.ant-radio-checked .ant-radio-inner:focus{
  border-color: red !important; 
} */

.ant-modal {
  /* max-width: unset; */
  margin-bottom: 100px;
}


.notification_wrap{
	width: auto;
  position: relative;
  /* right:60% */
 

	/* margin: 120px auto 0; */
}

.notification_wrap .notification_icon{
	position: relative;
	width: 50px;
	height: 50px;
	font-size: 32px;
	margin: 0 auto;
	text-align: center;
	color: #605dff;
}



.notification_wrap .dropdown{
	width: 350px;
	height: auto;
	background: #fff;
	border-radius: 5px;
  -webkit-box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.23);
  -moz-box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.23);
  box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.23);
	padding: 15px;
	position: relative;
	display: none;
}

.notification_wrap .dropdown .notify_item{
	display: flex;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid #dbdaff;
}

.notification_wrap .dropdown .notify_item:last-child{
	border-bottom: 0px;
}

.notification_wrap .dropdown .notify_item .notify_img{
	/* margin-right: 1px; */
}

.notification_wrap .dropdown .notify_item .notify_info p{
	margin: 10px 10px;
}

.notification_wrap .dropdown .notify_item .notify_info p span{
	color: #605dff;
	/* margin-left: 5px; */
}

.notification_wrap .dropdown .notify_item .notify_info .notify_time{
	color: #c5c5e6;
	font-size: 12px;
}



.notification_wrap .dropdown.active{
	display: block;
    position: absolute; 
   /* right: 0px;
  top: 0px;  */
  z-index: 1;
  border-radius: 30px;
  height: 600px;
  width: 450px;
  background-color:  rgba(250, 250, 250, 1);
  right:60%
  ;
}
.scrollbar-color-dash{
  height: 500px;
}
/* Works on Chrome, Edge, and Safari */
.scrollbar-color-dash::-webkit-scrollbar {
  width: 6px;
  height:30px;
}

.scrollbar-color-dash::-webkit-scrollbar-track {
  background:  rgba(250, 250, 250, 1);
}

.scrollbar-color-dash::-webkit-scrollbar-thumb {
  background-color: rgb(214, 112, 29);
  border-radius: 12px;
  height:30px;
  /* right:60% */
  /* border: 2px solid orange; */
}

.closeimgclass{
  /* position : relative !important;
right: -50px !important; */
}


@media only screen and (max-width: 600px) {
  .mediaAdjust {
  display: none !important;
  }
  .login-container-otaxi{
    width: 100% !important;
  }
}