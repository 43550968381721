.ant-table-wrapper .ant-table-thead >tr>th{
    background-color: white;
    color: #8A8A8A;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.307073px;
}
.ant-table-wrapper .ant-table-tbody>tr>td{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #29273A;
}
:where(.css-dev-only-do-not-override-k83k30).ant-select:not(:where(.css-dev-only-do-not-override-k83k30).ant-select-customize-input) .ant-select-selector{
    background: rgba(242, 101, 34, 0.05);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #29273A;
}
:where(.css-dev-only-do-not-override-k83k30).ant-modal .ant-modal-title{
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: start;
    color: #29273A;
}
:where(.css-dev-only-do-not-override-k83k30).ant-modal .ant-modal-close{
    top: 25px;
    width: 30px;
    height: 30px;
}
.scroll::-webkit-scrollbar-thumb{
   padding: 4rem !important;
}
.toggleContainer{
    display:flex;
    justify-content: flex-end;
    width:100%;
    padding-bottom: 20px;
}
.driveronboarding-input{
    color: black !important;
}
.ant-select-selector.select1{
    border: none !important;
}
// .zoom {
//     zoom: 0.9;
// }

// .zoom-moz {
//     -moz-transform: scale(1);
//     -moz-transform-origin: 0 0;
// }
// .ant-select-selection-selected-value {
//    left: 10px;
//   }

// .ant-select-selector{
//     border: none;
// }

// :where(.css-dev-only-do-not-override-k83k30).ant-select:not(:where(.css-dev-only-do-not-override-k83k30).ant-select-customize-input) .ant-select-selector {
//     position: relative;
//     background-color: #ffffff;
//     /* border: 1px solid #d9d9d9; */
//     transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
// }

// .ant-select-focused .ant-select-selector,
// .ant-select-selector:focus,
// .ant-select-selector:active,
// .ant-select-open .ant-select-selector {
//   border-color: #d9d9d9 !important;
//   box-shadow: none !important;
// }

// .ant-select-selector {
//     background-color: green;
//   }



:where(.css-k83k30).ant-modal .ant-modal-title{
    font-size: 30px !important;
}

.contract-field ,.contract-add-button{
    background: none !important;
    border: 1px solid black !important;
    border-radius: 10px !important;
  }